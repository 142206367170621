import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offCanvesShow: 0,
  profile: 0,

};



export const offCanvesSlice = createSlice({
  name: 'offcanves',
  initialState,
  reducers: {
    // actions
    showCanves: (state) => {
      state.offCanvesShow = 1;
    },
    hideCanves: (state) => {
      state.offCanvesShow = 0;
    },
    showProfile: (state) => {
      state.profile = 1;
    },
    hideProfile: (state) => {
      state.profile = 0;
    },
  },

});

export const { showCanves, hideCanves, showProfile, hideProfile } = offCanvesSlice.actions;


export default offCanvesSlice.reducer;
