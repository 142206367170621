import {
  API_URL,
  API_URL_UPDATED,
  APP_GUID,
  APP_NAME,
  APP_VERSION,
  FLITE_API_AZURE,
  VENUE_GUID,
  appInfo,
} from "../constants";
import api from "./api";

class DataService {
  app_info = appInfo;


  async getEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-employees",
      payload
    );
  }

  async getVenueDateTime(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-effective-venue-date",
      payload
    );
  }


  async updateEmployee(payload = {}) {
    return api.post(API_URL_UPDATED + "employees-module/update-employee-avatar", payload);
  }

  async getAvatar(payload = {}) {
    return api.post(API_URL_UPDATED + "employees-module/get-avatars", payload);
  }

  async getEmployeeByRowGuid(payload = {}) {
    return api.post(
        API_URL_UPDATED + "employees-module/get-employee-details",
        payload
    );
  }

  async getAppAccessSingle(payload = {}) {
    return api.post(API_URL_UPDATED + "appsecurity-module/get-app-access", payload);
  }

  async getAppAccess(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
  }

  async getAppAccessAwait(payload = {}) {
    const res = await api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
    return res;
  }

  async findByEmail(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/find-by-email",
      payload
    );
  }

  async getReportByDay(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-golfing-kpi-report-by-day",
      payload
    );
  }

  async getReportByMonth(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-golfing-kpi-report-by-month",
      payload
    );
  }

  async getReportByWeek(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-golfing-kpi-report-by-week",
      payload
    );
  }

  async getReportByCustomDates(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-golfing-kpi-report-custom",
      payload
    );
  }


  async getReportByDayForFAB(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-food-and-bev-kpi-report-by-day",
      payload
    );
  }

  async getReportByMonthForFAB(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-food-and-bev-kpi-report-by-month",
      payload
    );
  }

  async getReportByWeekForFAB(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-food-and-bev-kpi-report-by-week",
      payload
    );
  }

  async getReportByCustomDatesForFAB(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-food-and-bev-kpi-report-custom",
      payload
    );
  }


  async getReportByDayForEvents(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-event-kpi-for-day",
      payload
    );
  }

  async getReportByMonthForEvents(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-event-kpi-for-month",
      payload
    );
  }

  async getReportByWeekForEvents(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-event-kpi-for-week",
      payload
    );
  }

  async getReportByCustomDatesForEvents(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-event-kpi-for-dates",
      payload
    );
  }


  async getReportByDayForFastPass(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-fastpass-kpi-for-day",
      payload
    );
  }

  async getReportByMonthForFastPass(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-fastpass-kpi-for-month",
      payload
    );
  }

  async getReportByWeekForFastPass(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-fastpass-kpi-for-week",
      payload
    );
  }

  async getReportByCustomDatesForFastPass(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-fastpass-kpi-for-dates",
      payload
    );
  }

  

  async getReportByDayForTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-fastpass-kpi-for-day",
      payload
    );
  }

  async getReportByMonthForTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-weekday-weekend-trending-for-month",
      payload
    );
  }

  async getReportByWeekForTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-weekday-weekend-trending-for-week",
      payload
    );
  }

  async getReportByCustomDatesForTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-weekday-weekend-trending-for-dates",
      payload
    );
  }


  

  async getReportByDayForProfitCenterTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-fastpass-kpi-for-day",
      payload
    );
  }

  async getReportByMonthForProfitCenterTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-profit-center-trending-for-month",
      payload
    );
  }

  async getReportByWeekForProfitCenterTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-profit-center-trending-for-week",
      payload
    );
  }

  async getReportByCustomDatesForProfitCenterTrending(payload = {}) {
    return api.post(
      API_URL_UPDATED + "businessreporting-module/get-profit-center-trending-for-dates",
      payload
    );
  }
  
}

export default new DataService();
